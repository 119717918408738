.container {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #6c757d;
    height: 50px;
}

.menuBtn {
    color: #e2e2e2;
    margin: 5px;
}

.appName {
    flex-grow: 1;
    text-align: center;
    font-weight: bolder;
    font-size: 28px;
    color: #e2e2e2;
    user-select: none;
}

.categoryLinks {
    background-color: #e2e2e2;
    margin: 5px 10px ;
    font-size: 18px;
    padding: 10px 60px;
    cursor: pointer;
}

.categoryLinks:hover {
    background-color: turquoise;
}

@media screen and (max-width: 600px){
    .appName {
        text-align: left;
    }
}
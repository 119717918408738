.container {
    width: fit-content;
}


.categoryLinks {
    /* background-color: #eeeeee; */
    margin: 8px 10px;
    font-size: 18px;
    padding: 5px;
    text-align: left;
    cursor: pointer;
    width: 180px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: #9e9e9e;
    user-select: none;
}

.categoryLinks:hover {
    background-color: #6c757d;
    color: white;
}

.DrawerContentWrapper {
    height: 100%;
    background-color: #393e46;
    padding: 1px 0px;

}

.drawerIcons {
    font-size: 20px;
    width: 25px;
    margin-left: 10px;
}

.drawerIconText {
    margin: 5px;
}
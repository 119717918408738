.App {
  background-color: #aabbcf;
  background-color: #222831;
  text-align: center;
  min-width: 100vw;
  min-height: 100vh;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  margin: 0px;
  padding: 0px;
  height: fit-content;
}